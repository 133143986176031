import React from 'react';
import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import SocialProofQuoteCardST from '@components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import GetStartedST from '@components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '@components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '@components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import Testimonial from '@components/sections/testimonial/testimonial';
import OutcomeST from '@components/staticSections/OutcomeST/OutcomeST';
import { LANG_SE_SV } from '@/src/common/consts/lang';
import HeroListing from '@components/sections/heroListing/HeroListing';
import { HERO_IMAGE_VARIANT_KNEE_PAIN } from '@/src/common/consts/hero';
import { THEME_LIGHT, THEME_WHITE } from '@/src/common/consts/theme';
import Treatments from '@components/staticSections/Treatments/Treatments';
import { CTA_DATA_GOAL_ONBOARDING_VIEWED } from '@/src/common/consts/tracking';

const PatientPageSE = () => {
  const lang = LANG_SE_SV;

  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_SE} />
      <Seo title="Behandla artros och ledsmärta direkt i mobilen" language="sv" />

      <HeroListing
        imageVariant={HERO_IMAGE_VARIANT_KNEE_PAIN}
        imageAlt="Joint Academy Patient"
        title="Behandla artros och ledsmärta direkt i mobilen"
        list={[
          { li: 'Personlig fysioterapeut' },
          { li: 'Anpassade aktiviteter' },
          { li: 'Följ din utveckling' },
        ]}
        showSmsTag
        smsTagLine="Få ett sms med en länk till appen"
      />

      <SocialProofQuoteCardST
        lang={lang}
        dataTheme={THEME_WHITE}
        extraMargin="margin-top--lg"
      />

      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="Kom igång idag"
        ctaDataGoal={CTA_DATA_GOAL_ONBOARDING_VIEWED}
        ctaHref="#herotext"
      />

      <BgCardsTextST dataTheme={THEME_LIGHT} lang={lang} />

      <Treatments lang={lang} downloadhref="#herotext" />

      <SocialProofFeedST lang={lang} />

      <Testimonial
        dataTheme={THEME_WHITE}
        renderTitle
        titleClassName=""
        videoSrcURL="https://player.vimeo.com/video/332237175?color=fff&title=0&byline=0&portrait=0"
        videoTitle="“I can’t imagine a day without Joint Academy”"
      />

      <LicensedPtSliderST
        lang={lang}
        downloaddataGoal={CTA_DATA_GOAL_ONBOARDING_VIEWED}
        downloadhref="#herotext"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Kom igång idag"
      />

      <OutcomeST lang={lang} />
    </Layout>
  );
};

export default PatientPageSE;
